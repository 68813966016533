$link-decoration: none;

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer * 0.75,
    4: $spacer * 1,
    5: $spacer * 1.25,
    6: $spacer * 1.5,
    7: $spacer * 2,
    8: $spacer * 3,
    9: $spacer * 4,
    10: $spacer * 5,
);

$min-contrast-ratio: 3; // Чтобы цвет текста в кнопке не перекрашивался на черный

$blue: #1983dd; // Делаем основной цвет похожим на наш
$yellow: #e79a0c;
$green: #35A916;

$font-size-root: 18px;
$font-size-base: 1rem;

$headings-font-weight: 700;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.35;
$h4-font-size: $font-size-base * 1.125;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base * 0.875;

$blockquote-font-size: $h4-font-size;

$btn-focus-width: 0;
$btn-focus-box-shadow: none;

$enable-negative-margins: true;
$enable-smooth-scroll: false;
$carousel-indicator-active-bg: $blue;

$tooltip-max-width: 300px;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$utilities: (
    "bg": (
        property: "background",
        class: "bg",
        responsive: true,
        values: (
            light-green: (radial-gradient(circle at 3% -6%, rgba(255, 218, 161, .59), hsla(0, 0%, 100%, 0) 68%), linear-gradient(180deg, #e9ffe2, #e9ffe2)),
            purple: linear-gradient(-6deg, #db45fb 0%, #3F5EFB 100%),
            cyan: (radial-gradient(100% 100% at 100% 0, rgba(163, 255, 183, .5) 0, hsla(0, 0%, 100%, 0) 100%), #daeeff),
            blue: (radial-gradient(100% 100% at 100% 0%, rgba(177, 231, 221, 0.5) 0%, rgba(255, 255, 255, 0.0001) 100%), #EAF5FF),
        )
    ),
    "font-size": (
        rfs: true,
        property: "font-size",
        class: "fs",
        values: $font-sizes,
        responsive: true,
    ),
    "overflow": (
        property: overflow,
        responsive: true,
        values: auto hidden visible scroll,
    ),
);


@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack


.exit-container-lg {
    width: 100vw;
    margin-left: calc(-1 * ($container-padding-x / 2));

    --bs-gutter-x: #{$container-padding-x};

    @include media-breakpoint-up(lg) {
        margin-left: calc(-0.5 * (100vw - map-get($map: $container-max-widths, $key: lg)) - ($container-padding-x / 2));
    }

    @include media-breakpoint-up(xl) {
        margin-left: calc(-0.5 * (100vw - map-get($map: $container-max-widths, $key: xl)) - ($container-padding-x / 2));
    }

    @include media-breakpoint-up(xxl) {
        margin-left: calc(-0.5 * (100vw - map-get($map: $container-max-widths, $key: xxl)) - ($container-padding-x / 2));
    }
}

.w-xl-auto {
    @include media-breakpoint-up(xl) {
        width: auto !important;
    }
}

// Библиотека react-phone-input-2 переписывает стили бутстрапа,
// поэтому мы создадим более сильный класс наследованный от Bootstrap
body .react-tel-input .override-form-control {
    @extend .form-control;
}

body {
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: $spacer;
}

ul {
    padding-left: 1rem;
}

ul.checklist {
    padding-left: 1.25rem;
    list-style-type: none;
    margin-bottom: 0;

    &:last-of-type {
        margin-bottom: 1rem;
    }

    li {
        margin-bottom: $spacer * .3;

        &:before {
            content: "";
            margin-left: -1.2rem;
            margin-right: 0.35rem;
            position: relative;
            top: 0.15rem;
            display: inline-block;
            width: 0.9rem;
            height: 0.9rem;
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="%2335A916" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

.hero ul.checklist {
    font-size: $font-size-root;
}

.btn-link:not(.without-hover):hover {
    background: rgba(var(--bs-primary-rgb), 0.05);
}

a.text-muted:hover {
    color: var(--bs-primary) !important;
}

// Головний контейнер з контентом
main>.container-lg {
    @include media-breakpoint-up(md) {
        &> h1, h2, h3, h4, h5, h6 {
            margin-top: $spacer * 1.5;
        }
    }

    &> h1, h2, h3, h4, h5, h6 {
        margin-top: $spacer * 2;
    }

    iframe[src*="youtube.com"] {
        aspect-ratio: 16 / 9;
        max-width: 100%;
        height: auto;
    }
}

.alert p:last-child {
    margin-bottom: 0;
}
